import { NFT, PINATA } from "../models/NFT";
import { getIPFSPinList } from "../pinataAPI";
import { PinataChapters, OpenSeaOnlyChapters } from "../enums/chapters";

const PINATA_CLOUD_URL = 'https://ivory-able-shrew-504.mypinata.cloud/ipfs/';

const options = {
  method: 'GET',
  headers: {
    accept: 'application/json',
    'X-API-KEY': `${process.env.REACT_APP_OPENSEA_API_KEY}`
  }
};

export const getAllCollections = async () => {
  let collections = new Array<Array<NFT>>();

  const startDate = new Date(2024, 0, 5);
  const todaysDate = new Date();
  const days = date_diff_indays(startDate, todaysDate);

  const numberOfNFTsAfter45 = (days / 7) | 0;
  const totalNFTsReleased = 45 + numberOfNFTsAfter45;
  const storedNfts = localStorage.getItem("nfts") ?? "";
  let nftDate = localStorage.getItem("nftDate") ?? "";
  let numberOfNFTs = 0;

  if(storedNfts !== ""){
    const parsedNFTs = JSON.parse(storedNfts);
    numberOfNFTs = parsedNFTs.flat().flat().length;
  }

  if(nftDate === ""){
    localStorage.setItem("nftDate", JSON.stringify(todaysDate.getTime() + 86400000));
    nftDate = JSON.parse(localStorage.getItem("nftDate") ?? "");
  }

  if(
    // Locally stored nfts have less nfts than released
    numberOfNFTs < totalNFTsReleased ||
    // No locally stored nfts
    storedNfts === "" ||
    // 1 day old cache
    JSON.parse(nftDate) < todaysDate.getTime()
    ){
    // OpenSea + Pinata pinned chapters:
    const openSeaAndPinata = Object.values(PinataChapters);
    const pinataList = await getIPFSPinList();

    for(const key of openSeaAndPinata) {
      const chapter = await getPinataPinnedCollection(key, pinataList);
      collections.push(chapter);
    }

    // OpenSea pinned chapters:
    const openSeaOnly = Object.values(OpenSeaOnlyChapters);
    for(const key of openSeaOnly) {
      const chapter = await getOpenSeaCollection(key);
      collections.push(chapter);
    }

    localStorage.setItem("nftDate", JSON.stringify(todaysDate.getTime() + 86400000));
    localStorage.setItem("nfts", JSON.stringify(collections));
    collections = JSON.parse(localStorage.getItem("nfts") ?? "");
  }
  else {
    collections = JSON.parse(localStorage.getItem("nfts") ?? "");
  }

  return collections.flat().flat();
}

// Get single OpenSea collection
const getOpenSeaCollection = async (collectioName: string) => {
  const collectionRespons = await fetch(`https://api.opensea.io/v2/collection/${collectioName}/nfts?limit=100`, options)
      .then(response => response.json());
      //.catch(err => console.error(err));

  const collection: Array<NFT> = collectionRespons?.nfts?.map((NFT: {
    image_url: string;
    description: any;
    collection: any;
    name: any;
    identifier: any;
    contract: any;
}) => ({
    identifier: NFT.identifier,
    name : NFT.name,
    collection : NFT.collection,
    description: NFT.description,
    image_url: NFT.image_url,
    contract: NFT.contract
  }));

  return collection;
}

const getPinataPinnedCollection = async (chapterName: string, pinataList: PINATA[]) => {
  let collection = new Array<NFT>();
  const openSeaChapterMetadata = (await getOpenSeaCollection(chapterName)).reverse();

  const chapterIpfsCID = pinataList.find((n: { name: string; }) => n.name === chapterName)?.ipfs_pin_hash;
  const pinataChapterFolderURL = PINATA_CLOUD_URL + chapterIpfsCID;

  /**
   * Chapter predefined was unintentionally split into different ipfs folders
   */

  let i = 1;
  openSeaChapterMetadata.forEach(nft => {
    if(chapterName === PinataChapters.CHAPTER_PREDEFINED){
      if(nft.identifier === "1"){
        nft.image_url = pinataChapterFolderURL + `/${i}?img-width=1000`;
      }
      else {
        let hash = pinataList.find((n: { name: string; }) => n.name === PinataChapters.CHAPTER_PREDEFINED + `-${i}`)?.ipfs_pin_hash;
        nft.image_url = PINATA_CLOUD_URL + hash + `/${i}?img-width=1000`
      }
    }
    else if(chapterName === PinataChapters.CHAPTER_DEJAVU){
      if(nft.identifier === "1"){
        nft.image_url = pinataChapterFolderURL + `/${i}?img-width=1000`;
      }
      else {
        let hash = pinataList.find((n: { name: string; }) => n.name === PinataChapters.CHAPTER_DEJAVU + `-${i}`)?.ipfs_pin_hash;
        nft.image_url = PINATA_CLOUD_URL + hash + `/${i}?img-width=1000`
      }
    }
    else if(chapterName === PinataChapters.CHAPTER_MAGNITUDE){
      if(nft.identifier === "1"){
        nft.image_url = pinataChapterFolderURL + `/${i}?img-width=1000`;
      }
      else {
        let hash = pinataList.find((n: { name: string; }) => n.name === PinataChapters.CHAPTER_MAGNITUDE + `-${i}`)?.ipfs_pin_hash;
        nft.image_url = PINATA_CLOUD_URL + hash + `/${i}?img-width=1000`
      }
    }
    else if(chapterName === PinataChapters.CHAPTER_TRANSCENDENCE){
      if(nft.identifier === "1"){
        nft.image_url = pinataChapterFolderURL + `/${i}?img-width=1000`;
      }
      else {
        let hash = pinataList.find((n: { name: string; }) => n.name === PinataChapters.CHAPTER_TRANSCENDENCE + `-${i}`)?.ipfs_pin_hash;
        nft.image_url = PINATA_CLOUD_URL + hash + `/${i}?img-width=1000`
      }
    }
    else if(chapterName === PinataChapters.CHAPTER_SAMSARA){
      if(nft.identifier === "1"){
        nft.image_url = pinataChapterFolderURL + `/${i}?img-width=1000`;
      }
      else {
        let hash = pinataList.find((n: { name: string; }) => n.name === PinataChapters.CHAPTER_SAMSARA + `-${i}`)?.ipfs_pin_hash;
        nft.image_url = PINATA_CLOUD_URL + hash + `/${i}?img-width=1000`
      }
    }
    else{
      nft.image_url = pinataChapterFolderURL + `/${i}?img-width=1000`;
    }
    collection.push(nft);
    i++;
  });
  
  return collection.reverse();
}

// Private helper method to calculate number of days between two dates
const date_diff_indays = function (date1: Date, date2: Date) {
  const dt1 = new Date(date1);
  const dt2 = new Date(date2);
  return Math.floor(
    (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
      Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
      (1000 * 60 * 60 * 24)
  );
}