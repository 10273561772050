import React, { useEffect, useState } from "react";
import { makeNFTOffer } from "../../../services/opensea/osMakeOffer";
import "./MakeOffer.css";
import { mainnet } from '@wagmi/core/chains'
import { formatUnits } from 'viem'
import DateDropdown from "./DateDropdown";
import { GetCurrentDate, CalculateNewDate } from "../../../helpers/GetCurrentDate";
import { useAccount, useBalance } from 'wagmi';

const MakeOffer = ({onClose, selectedNFT}) => {
  const [priceOffered, setPriceOffered] = useState<string | number>("--");
  const [priceError, setPriceError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [currentDate, setCurrentDate] = useState(GetCurrentDate());
  const { address, isConnected } = useAccount();
  const [makeOfferButtonDisabled, setMakeOfferButtonDisabled] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const timeIntervalsArray = ["12 Hours", "1 day", "3 days", "7 days", "1 month", "Custom"];
  const [timeInterval, setTimeInterval] = useState("3 days");
  
  useEffect(() => {
    setCurrentDate(GetCurrentDate());
    setPriceOffered("--");
    setMakeOfferButtonDisabled(true);
    setIsDropdownOpen(false);

    if(timeInterval){
      var newDate = CalculateNewDate(timeInterval);
      setCurrentDate(newDate);
    }
    
    document.body.style.overflowY = 'hidden';
    return () =>{
      document.body.style.overflowY = 'auto';
    }
  }, [timeInterval])

  const { data, error, isError } = useBalance({ 
    address: address,
    token: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    chainId: mainnet.id 
  });

  if (isError) {
    console.error('Error fetching balance:', error);
  }

  const chapterSubstring = (chapter: string | undefined) => {
    const ch = chapter?.split("-").pop() ?? "";
    const capitalized = "ColorFuel: " + ch.charAt(0).toUpperCase() + ch.slice(1);
    return capitalized;
  }

  const handleDateStateChange = (event) => {
    const newDate = event.target.value;
    setCurrentDate(newDate);
  }

  function priceInput(input) {
    // check for only valid number
    // check for minimum value
    // check if balance is enough
    // max length of decimals

    var isNumber = /^\d{1,6}(\.\d{1,5})?$/.test(input);
    var balanceTooSmall = false;
    if(data){
      balanceTooSmall = formatUnits(data.value, data.decimals) < input;
    }
    var minimumOffer = input < 0.0020;
    const normalizePrice = input.replace(",", ".");

    if(isNumber && !balanceTooSmall && !minimumOffer){
        setPriceError(false);
        setPriceOffered(normalizePrice);
        setMakeOfferButtonDisabled(false);
    }
    if(isNumber && balanceTooSmall){
      setPriceError(true);
      setPriceOffered(normalizePrice);
      setErrorMessage("You dont have enough WETH");
      setMakeOfferButtonDisabled(true);
    }
    if(isNumber && minimumOffer){
      setPriceError(true);
      setPriceOffered("--");
      setErrorMessage("Offer must be at least the minimum price of 0.0020 WETH");
      setMakeOfferButtonDisabled(true);
    }
    if(!isNumber){
      setPriceError(true);
      setPriceOffered("--");
      setErrorMessage("Please enter a valid amount");
      setMakeOfferButtonDisabled(true);
    }
    if(!input){
      setPriceError(false);
      setPriceOffered("--");
      setMakeOfferButtonDisabled(true);
    }
  }

  const handleMakeOffer = async () => {
    const message = await makeNFTOffer(
      isConnected,
      selectedNFT?.identifier,
      selectedNFT?.contract,
      priceOffered,
      currentDate
    );
    if(message.includes("Failed")){
      onClose(false, message, 'error');
    }
    else {
      onClose(false, message, 'success');
    }
  };

  const CloseButton = () => {
    onClose(false, "");
  }
      
  return (
    <div className="overlay">
      <div className="modal gradient">
      <button className="closeButton" onClick={CloseButton}>X</button>
      <div className="offerTitle">Make an offer</div>
      <div className="modalNftInfo">
        <div className="modalNftInfoBox">
          <img
            className="modalNftPicture"
            key={selectedNFT?.name}
            src={selectedNFT?.image_url}
            alt="nft"
          />
          <div className="modalNftDescription">
            <div className="modalNftName">{selectedNFT?.name.replace(/^#\d+\s*/, '')}</div>
            <div className="modalNftCollection">{chapterSubstring(selectedNFT?.collection)}</div>
          </div>
        </div>
        <div className="modalNftOfferPriceBox">
          <div><b>{priceOffered}</b> WETH</div>
        </div>
      </div>

      <div className="modalNftStatsContent">
        <div className="walletBalanceContainer">
          <div>Wallet Balance</div>
          <div className="wethWalletBalance">
            {data && <div>{formatUnits(data.value , data.decimals)}</div> }
            <div>WETH</div>
          </div>
        </div>
      </div>

      <div className="priceContent">
        <input 
          onChange={value => priceInput(value.target.value)} 
          name="priceInput" 
          className="priceInput" 
          placeholder="Price"
          autoComplete="off"
          />
        <div className="priceValuta">WETH</div>
      </div>
      {priceError && <div className="errorMessage">{errorMessage}</div>}

      <div className="duration">Duration</div>
      <div className="datePickerContainer">
        <DateDropdown
          timeIntervalsArray={timeIntervalsArray}
          isDropdownOpen={isDropdownOpen}
          setIsDropdownOpen={setIsDropdownOpen}
          timeInterval={timeInterval}
          setTimeInterval={setTimeInterval}
        />
        <span className="datepicker-content">
          <span className="datepicker-toggle-button"></span>
          <input value={currentDate} 
            type="datetime-local" 
            date-format="MM-DD-YYYY- HH:mm" 
            onChange={handleDateStateChange} 
            className="datepicker-input"
            min={currentDate}
            lang="en"/>
        </span>
      </div>
        <div className="makeOfferModalButtonContainer">
          <button
            disabled={makeOfferButtonDisabled}
            className="makeOfferModalButton"
            onClick={handleMakeOffer}>
              Make Offer
          </button>
        </div>
      </div>
    </div>
  );
}

export default MakeOffer;