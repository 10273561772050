export enum PinataChapters {
    CHAPTER_SAMSARA = 'colorfuel-samsara',
    CHAPTER_TRANSCENDENCE = 'colorfuel-transcendence',
    CHAPTER_MAGNITUDE = 'colorfuel-magnitude',
    CHAPTER_DEJAVU = 'colorfuel-dejavu',
    CHAPTER_PREDEFINED = 'colorfuel-predefined',
    CHAPTER_VOYAGE = 'colorfuel-voyage',
    CHAPTER_INTERLUDE = 'colorfuel-interlude'
};

export enum OpenSeaOnlyChapters {
    CHAPTER_NOSTALGIA = 'colorfuel-nostalgia',
    CHAPTER_UNIVERSAL = 'colorfuel-universal',
    CHAPTER_PRIMER = 'colorfuel-primer'
};